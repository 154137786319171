import {
  Context,
  TransactionStatus,
  TransactionTemplateFragment,
} from "@earnnest-e2-frontend/platform-api/src/graphql"
import { MetaRow } from "@earnnest-e2-frontend/platform-ui/src/mantine"
import AmountText from "@earnnest-e2-frontend/platform-ui/src/mantine/AmountText"
import {
  FundingSourceStatusBadge,
  TransactionTemplateStatusBadge,
} from "@earnnest-e2-frontend/platform-ui/src/mantine/StatusBadges"
import {
  Accordion,
  Space,
  Text,
  Title,
  Tooltip,
  useMantineTheme,
} from "@mantine/core"
import { RiAddLine } from "react-icons/ri"
import { Link, useLocation } from "react-router-dom"

const friendlyTransferStatus = {
  initiated: "Initiated",
  "pending:funds": "Initiated",
  "pending:deposit": "Cleared",
  complete: "Deposited",
}

export default function TransactionDetails({
  transactionTemplate,
  viewerRole = "agent",
}: {
  transactionTemplate: TransactionTemplateFragment
  viewerRole: "superuser" | "admin" | "agent"
}) {
  const location = useLocation()
  const theme = useMantineTheme()

  const paymentOccasion = transactionTemplate.paymentOccasion
  const transaction = transactionTemplate.transactions?.[0]
  const escrowAccount = transactionTemplate.escrowAccount

  const transactionSource = transaction?.integration
    ? `${transaction.integration.name} Integration`
    : paymentOccasion?.context === Context.Refund
    ? "Refund"
    : paymentOccasion?.context === Context.Emd
    ? transactionTemplate.receiverEmail
      ? "EMD Request"
      : "EMD Link"
    : transactionTemplate.receiverEmail
    ? "Generic Request"
    : "Generic Link"

  return (
    <>
      <Space h="xl" />
      <Title size="h4" order={4}>
        Transaction
      </Title>
      {transactionTemplate?.paymentOccasion.context === Context.Emd ? (
        <MetaRow label="Address">
          {transactionTemplate.address ||
            transactionTemplate.formData?.find(
              (x) => x.name === "propaddress" || x.name === "property_address",
            )?.value}
        </MetaRow>
      ) : null}
      <MetaRow label="Transaction Source">{transactionSource}</MetaRow>
      <MetaRow label="Transfer Type">
        {transactionTemplate.isAch ? "ACH" : "Credit Card"}
      </MetaRow>
      <MetaRow label="Amount">
        <AmountText
          amount={transactionTemplate.amount}
          direction={transactionTemplate.paymentOccasion?.direction}
        />
      </MetaRow>
      {viewerRole === "superuser" ? (
        <MetaRow label="Gated Payment">
          {escrowAccount?.gated ? "Yes" : "No"}
        </MetaRow>
      ) : null}
      <MetaRow label="Expedited">
        {transaction?.expedited ? "Yes" : "No"}
      </MetaRow>
      {viewerRole === "superuser" ? (
        <MetaRow label="Dwolla Status">
          {transaction?.status === TransactionStatus.Failed
            ? "Failed"
            : friendlyTransferStatus[transaction?.transferStatus] ?? "None"}
        </MetaRow>
      ) : null}
      <MetaRow label="Earnnest Status">
        <TransactionTemplateStatusBadge
          transactionTemplate={transactionTemplate}
        />
      </MetaRow>
      <MetaRow label="Emails Notified">
        {getUniqueEmails(transactionTemplate).join(", ")}
      </MetaRow>
      <Space h="xl" />
      <Title order={4}>Payer</Title>
      {/* TODO: will this show payer for epro payments? */}
      {transactionTemplate.buyer ? (
        <>
          {viewerRole === "superuser" ? (
            <MetaRow label="Link">
              <Link
                to={{
                  pathname: `/users/${transactionTemplate.buyer?.id}`,
                  state: location.state,
                }}>
                {transactionTemplate.buyer?.fullName}
              </Link>
            </MetaRow>
          ) : (
            <MetaRow label="Name">{transactionTemplate.buyer.fullName}</MetaRow>
          )}
          <MetaRow label="Email">{transactionTemplate.buyer.email}</MetaRow>
          <MetaRow label="Phone">{transactionTemplate.buyer.phone}</MetaRow>
          {viewerRole === "superuser" ? (
            <MetaRow label="Dwolla Status">
              {transactionTemplate.buyer.customer?.status || "No status"}
            </MetaRow>
          ) : null}
          {viewerRole === "superuser" ? (
            <MetaRow label="Funding Status">
              {transaction?.originationFundingSource ? (
                <FundingSourceStatusBadge
                  fundingSource={transaction.originationFundingSource}
                />
              ) : (
                "No status"
              )}
            </MetaRow>
          ) : null}
        </>
      ) : (
        <MetaRow label="No payer" />
      )}
      <Space h="xl" />
      <Title order={4}>Invite</Title>
      <MetaRow label="Invited Email">
        {transactionTemplate?.receiverEmail}
      </MetaRow>
      {/* <MetaRow label="Invited Phone">TODO</MetaRow> */}
      {/* <MetaRow label="Invited Name">TODO</MetaRow> */}
      <Space h="xl" />
      <Title order={4}>Requestor</Title>
      {transactionTemplate.buyerAgent ? (
        <>
          {viewerRole === "superuser" ? (
            <MetaRow label="Link">
              <Link
                to={{
                  pathname: `/users/${transactionTemplate.buyerAgent.id}`,
                  state: location.state,
                }}>
                {transactionTemplate.buyerAgent.fullName}
              </Link>
            </MetaRow>
          ) : (
            <MetaRow label="Name">
              {transactionTemplate.buyerAgent.fullName}
            </MetaRow>
          )}
          <MetaRow label="Email">
            {transactionTemplate.buyerAgent.email}
          </MetaRow>
          <MetaRow label="Phone">
            {transactionTemplate.buyerAgent.phone}
          </MetaRow>
        </>
      ) : (
        <MetaRow label="This transaction was initiated without a requestor." />
      )}
      <Space h="xl" />
      <Title order={4}>Listing Agent</Title>
      {transactionTemplate.listingAgent ? (
        <>
          {viewerRole === "superuser" ? (
            <MetaRow label="Link">
              <Link
                to={{
                  pathname: `/users/${transactionTemplate.listingAgent.id}`,
                  state: location.state,
                }}>
                {transactionTemplate.listingAgent.fullName}
              </Link>
            </MetaRow>
          ) : (
            <MetaRow label="Name">
              {transactionTemplate.listingAgent.fullName}
            </MetaRow>
          )}
          <MetaRow label="Email">
            {transactionTemplate.listingAgent.email}
          </MetaRow>
          <MetaRow label="Phone">
            {transactionTemplate.listingAgent.phone}
          </MetaRow>
        </>
      ) : (
        <MetaRow label="This transaction was initiated without a listing agent." />
      )}
      <Space h="xl" />
      <Title order={4}>Escrow Account</Title>
      {escrowAccount && paymentOccasion.context === Context.Emd ? (
        <>
          {viewerRole === "superuser" ? (
            <MetaRow label="Escrow Account">
              <Link
                to={{
                  pathname: `/escrow-accounts/${escrowAccount.id}`,
                  state: location.state,
                }}>
                {escrowAccount.name}
              </Link>
            </MetaRow>
          ) : (
            <MetaRow label="Name">{escrowAccount.name}</MetaRow>
          )}
          {viewerRole === "superuser" ? (
            <MetaRow label="Organization">
              <Link
                to={{
                  pathname: `/organizations/${escrowAccount.organization?.id}`,
                  state: location.state,
                }}>
                {escrowAccount.organization?.name}
              </Link>
            </MetaRow>
          ) : (
            <MetaRow label="Organization">
              {escrowAccount.organization?.name || "Not found"}
            </MetaRow>
          )}
          <MetaRow label="Bank Account">
            {transactionTemplate.escrowAccount.fundingSource.displayName}
          </MetaRow>
        </>
      ) : (
        <MetaRow label="No escrow account" />
      )}
      {/* {transactionTemplate.rejectReason ? (
            <MetaRow label="Reject Reason">
              {transactionTemplate.rejectReason}
            </MetaRow>
          ) : null} */}
      {/* <MetaRow label="Direction">
            {capitalize(transactionTemplate.paymentOccasion?.direction)}
          </MetaRow> */}
      {/* <MetaRow label="Payment Occasion">
            {transactionTemplate.paymentOccasion?.name}
          </MetaRow> */}
      {/* {transactionTemplate.authorizedBy ? (
            <MetaRow label="Authorized By">
              {transactionTemplate.authorizedBy.fullName}
            </MetaRow>
          ) : null} */}
      {/* {transactionTemplate.approvedBy?.[0] ? (
            <MetaRow label="Approved By">
              {transactionTemplate.approvedBy[0].fullName}
            </MetaRow>
          ) : null} */}
      {/* {transactionTemplate.fee ? (
            <MetaRow label="Fee">
              <AmountText amount={transactionTemplate.fee * 100} />
            </MetaRow>
          ) : null} */}
      {/* {transactionTemplate.receiverEmail ? (
            <MetaRow label="Receiver Email">
              {transactionTemplate.receiverEmail}
            </MetaRow>
          ) : null} */}
      {transaction ? (
        <>
          {/* <MetaRow label="Template ID">{transactionTemplate.id}</MetaRow> */}
          {/* {transaction?.id ? (
                <MetaRow label="Transaction ID">{transaction?.id}</MetaRow>
              ) : null} */}
          {/* {transaction?.traceId ? (
                <MetaRow label="Trace ID">EN-{transaction.traceId}</MetaRow>
              ) : null} */}
          {/* {transaction?.destinationFundingSource ? (
                <MetaRow label="Destination Account">
                  {transaction.destinationFundingSource.displayName}
                </MetaRow>
              ) : null} */}
          {/* {transactionTemplate?.additionalEmails ? (
                <MetaRow label="Notified Emails">
                  {transactionTemplate.additionalEmails.join(", ")}
                </MetaRow>
              ) : null} */}
          {/* {transaction?.originationFundingSource ? (
                <MetaRow label="Origination Account">
                  {transaction.originationFundingSource.displayName}
                </MetaRow>
              ) : null} */}
          {/* {transaction?.receiverUser ? (
                <MetaRow label="Receiver User">
                  {transaction.receiverUser.fullName}
                </MetaRow>
              ) : null} */}
          {/* {transactionTemplate?.receivingOrganization ? (
                <MetaRow label="Receiving Org">
                  {transactionTemplate.receivingOrganization.name}
                </MetaRow>
              ) : null} */}
          {/* {transaction?.sendingUser ? (
                <MetaRow label="Sending User">
                  {transaction.sendingUser.fullName}
                </MetaRow>
              ) : null} */}
        </>
      ) : null}
      {viewerRole === "superuser" ? (
        <>
          <Space h="xl" />
          <Accordion
            variant="filled"
            chevron={<RiAddLine color={theme.fn.themeColor("green")} />}
            styles={{
              content: {
                paddingLeft: 0,
              },
              control: {
                paddingLeft: 0,
              },
            }}>
            <Accordion.Item value="metadata" sx={{ height: 48 }}>
              <Accordion.Control>
                <Tooltip label="This is additional information that the agent or buyer filled in on the payment">
                  <Text size="xs" color="green">
                    Payment Metadata
                  </Text>
                </Tooltip>
              </Accordion.Control>
              <Accordion.Panel>
                {transactionTemplate.formData?.map((field) => (
                  <MetaRow key={field.name} label={field.name}>
                    {field.value}
                  </MetaRow>
                ))}
              </Accordion.Panel>
            </Accordion.Item>
          </Accordion>
        </>
      ) : null}
    </>
  )
}

function getUniqueEmails(transactionTemplate: TransactionTemplateFragment) {
  const emails = new Set()
  if (transactionTemplate.receiverEmail) {
    emails.add(transactionTemplate.receiverEmail)
  }
  if (transactionTemplate.authorizedBy?.email) {
    emails.add(transactionTemplate.authorizedBy.email)
  }
  if (transactionTemplate.buyer?.email) {
    emails.add(transactionTemplate.buyer.email)
  }
  if (transactionTemplate.additionalEmails) {
    transactionTemplate.additionalEmails.forEach((email) => emails.add(email))
  }
  return Array.from(emails)
}
